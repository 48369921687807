import React from "react";

interface PanelProps {
  title?: string;
}

const Panel: React.FC<PanelProps> = ({ title, children }) => (
  <div className="panel">
    <div className="panel__content">
      <h1 className="panel__copy-heading">{title}</h1>
      {children}
    </div>
  </div>
);

export default Panel;
